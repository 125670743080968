@tailwind base;
@tailwind components;
@tailwind utilities;

/* Add this to your CSS file */
@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

/* Apply this to your Tailwind config if needed */
.floating {
  animation: float 3s ease-in-out infinite;
}

.floating:hover {
  scale: 1.1;
}
