html,
body {
    scroll-behavior: smooth;
}

.perspective {
  perspective: 1000px;
}

.flip-card {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  perspective: 1000px; /* Added for Firefox */
}

.flip-card-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transform-origin: center; /* Explicitly set transform origin */
}

.flipped .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 24px;
}

.flip-card-front {
  z-index: 2;
  transform: rotateY(0deg); /* Added reset for potential Firefox issues */
}

.flip-card-back {
  transform: rotateY(180deg);
}


*{
    -ms-overflow-style: none;
}
::-webkit-scrollbar {
    display: none;
}


.custom-span {
  position: relative;
}

.custom-span::after {
  content: '';
  position: absolute;
  top: -50px;
  right: 0;
  width: 20px;  /* Adjust as needed */
  height: 25px; /* Adjust height to control "C" shape */
  border: 10px solid white; 
  border-radius: 0 1px 200px 0; /* Top-right and bottom-right corners curved */
  border-left: none; /* Remove left border to form a "C" */
  border-top: 0;
  background-color: transparent; /* No background color */
  transform: translate(55%, 155%) rotate(185deg); /* Adjust positioning as needed */
}

.custom-span-sm {
  position: relative;
}

.custom-span-sm::after {
  content: '';
  position: absolute;
  top: -50px;
  right: 0;
  width: 10px;  /* Adjust as needed */
  height: 15px; /* Adjust height to control "C" shape */
  border: 5px solid white; /* Border for visibility */
  border-radius: 0 1px 200px 0; /* Top-right and bottom-right corners curved */
  border-left: none; /* Remove left border to form a "C" */
  border-top: 0;
  background-color: transparent; /* No background color */
  transform: translate(60%, 340%) rotate(185deg); /* Adjust positioning as needed */
}

.slick-prev, .slick-next {
  background-color: gray !important; /* Background color */
  color: white !important; /* Icon color */
  border-radius: 50%; /* Rounded corners */
  z-index: 1; /* Ensure visibility */
}

.slick-prev:hover, .slick-next:hover {
  background-color: gray !important; /* Hover effect */
  color: white !important; /* Icon color */
}

@media (max-width: 640px) { /* Tailwind's 'sm' breakpoint: 640px */
  .custom-span::after {
    content: '';
    position: absolute;
    top: -50px;
    right: 0;
    width: 10px;  /* Adjust as needed */
    height: 15px; /* Adjust height to control "C" shape */
    border: 5px solid white; /* Border for visibility */
    border-radius: 0 1px 200px 0; /* Top-right and bottom-right corners curved */
    border-left: none; /* Remove left border to form a "C" */
    border-top: 0;
    background-color: transparent; /* No background color */
    transform: translate(50%, 320%) rotate(185deg); /* Adjust positioning as needed */
  }
}

@media (max-width: 640px) {
  .custom-span-sm::after {
    content: '';
    position: absolute;
    top: -50px;
    right: 0;
    width: 10px;  /* Adjust as needed */
    height: 15px; /* Adjust height to control "C" shape */
    border: 5px solid white; /* Border for visibility */
    border-radius: 0 1px 200px 0; /* Top-right and bottom-right corners curved */
    border-left: none; /* Remove left border to form a "C" */
    border-top: 0;
    background-color: transparent; /* No background color */
    transform: translate(50%, 320%) rotate(185deg); /* Adjust positioning as needed */
  }
}